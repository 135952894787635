import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSocketIO from 'vue-socket.io';
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { ToastOptionsAndRequiredContent } from 'vue-toastification/dist/types/types';
import IAccount from './models/interface.account';

const account: IAccount|null = JSON.parse(localStorage.getItem('profile') as string) || null;

const filterBeforeCreate = (toast:ToastOptionsAndRequiredContent, toasts:ToastOptionsAndRequiredContent[]) => {
	if (toasts.filter(t => t.type === toast.type).length !== 0) return false;
	return toast;
}

const app = createApp(App).use(router).use(VueAxios, axios).use(Toast, {
    transitionDuration: 200,
    transition: "Vue-Toastification__fade",
	containerClassName: "custom-container-class",
    position: POSITION.TOP_CENTER,
	filterBeforeCreate
});

/* app.use(new VueSocketIO({
    debug: true,
    connection: 'http://metinseylan.com:1992',
    options: {
        path: `${process.env.VUE_APP_API_CHATS_URL}/socket.io/`,
        autoConnect: false,
        reconnection: true,
        secure: true,
        extraHeaders: {
            Authorization: `Bearer ${account.token}`
        }
    }
})); */

app.mount('#app');