import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import IAccount from "@/models/interface.account";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Landing",
		component: () => import("@/views/Landing.vue")
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/Login.vue')
	},
	{
		path: '/restore-password',
		name: 'RestorePassword',
		component: () => import('@/views/RestorePassword.vue')
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import('@/views/Profile.vue')
	},
	{ path: '/:catchAll(.*)', component: () => import('@/views/NotFound.vue') },
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            if (savedPosition) {
                return savedPosition;
            } else {
                resolve({ left: 0, top: 0 });
            }
        });
	}
});

/* router.beforeEach((to, from, next) => {
    const account: IAccount | null = JSON.parse(localStorage.getItem('profile') as string) || null;
    if (!account) {
        if(to.name == 'Login' || to.name == 'RestorePassword') {
            return next();
        } else {
            return next({name: 'Login', params: { referer: to.fullPath }});
        }
    }

    if((to.name == 'Login' || to.name == 'RestorePassword') && account) {
        return next({ name: from.name || 'Edit' });
    }

    next();
}); */

export default router
