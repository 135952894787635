<template>
	<router-view></router-view>
</template>

<style lang="scss">
@import "@/sass/_fonts.scss";
@import "@/sass/_variables.scss";
@import "@/sass/_animation-transitions.scss";

* {
	margin: 0; padding: 0;
	font-family: $font;
	color: $text-color;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;  
}

header, main, footer {
    max-width: 1445px;
    margin: 0 auto;
}

@media screen and (max-width: 1485px) {
    .header, main, footer {
        padding: 20px !important;
    }
}
</style>
